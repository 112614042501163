
@import "Estilologin.css";
/*@import "material.css";*/
body {
    overflow-x: hidden;
    font-size: var(--font-size);
    margin-top: var(--body-margin-top);

    /*background-color: var(--main-color-body-background);*/
    background-color: snow;

}

h1 {
    font-size: var(--h1-font-size);

}

h2 {
    font-size: var(--h2-font-size);

}

h3 {
    font-size: var(--h3-font-size);
}

h4 {
    font-size: var(--h4-font-size);
}


/*barra de navegacion fixed*/
.u-fixed {

    position: fixed;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1em;
    width: 100%;
    background: var(--main-color-menu-negro);
    z-index: 1000;

}

.ui-icono {
    width: var(--main-width-icono);
    position: absolute;
    /*left:.8em;*/
    top: .5em;
    right: .8em
}

.ui-iconoMenu {
    position: absolute;
    left: .8em;
    /*top: .5em;*/

}

.ui-color-btn {
    background-color: var(--main-color-azulClaro);
}

.u-menu-hidden {
    position: fixed;
    left: -100vw;
    width: var(--menu-hidden-width);
    background-color: var(--main-color-menu-negro);
    height: 100%;
    -webkit-transition: all 80ms;
    -o-transition: all 80ms;
    transition: all 80ms;
    z-index: 999;
    padding-bottom: 4em;
}

.u-menu-up {
    left: 0;
    overflow-y: auto;
}

.nav-menu-hidden > ul {
    list-style: none;
    padding: 0;
}

.nav-menu-hidden > ul > li {
    position: relative;
    /* border-bottom:0.0625rem solid rgba(255,255,255,0.2);*/
}

.nav-menu-hidden > ul > li:before {
    position: absolute;
    height: 3em;
    width: .5em;
    content: "";
}

.nav-menu-hidden > ul > li:hover:before {
    background-color: transparent;
}

.nav-menu-hidden > ul > li > a {
    text-decoration: none;
    line-height: 3em;
    padding-left: 3em;
    padding-right: 3em;
    color: white;
    width: 100%;
    height: 100%;
}

.cerrarSesion {
    line-height: 3em;
    padding-left: 3em;
    padding-right: 3em;
    color: white;
    cursor: pointer;
}

.ui-submenu {
    -webkit-transition: max-height 1s;
    -o-transition: max-height 1s;
    transition: max-height 1s;
    max-height: 0;
    overflow: hidden;
}

.ui-submenu:target {
    max-height: 100em;
}

.ui-submenu > ul > li {
    border: 0;
}

.nav-menu-hidden > ul > li:hover {
    background: var(--main-color-azulClaro);
    color: black;
}

.nav-menu-hidden > ul > li:active {
    background: var(--main-color-azulClaro);
    color: #ffffff;
}


.nav-menu-hidden > ul > li > a:hover {
    text-shadow: 0.008em 0 #ffffff;
}

.ui-body-visible-transititon {
    /*padding: var(--padding-body);*/
    -webkit-transition: all 80ms;
    -o-transition: all 80ms;
    transition: all 80ms;
    /*margin-bottom:var(--padding-body);*/
    margin-bottom: 3em;
}

.ui-body-visible {
    margin-left: var(--margin-body-menu-hidden);
    padding-right: var(--padding-body-menu-hidden);
}

/*****************revisar*/
.div-contenedor {
    /*overflow-y: auto;*/
}

.ui-submenu > ul > li > a {
    margin-left: 2em;
    text-decoration: underline;
}

.table-informe-empleados {
    font-size: var(--font-size-table);
    table-layout: var(--fixed-column);
    overflow-wrap: break-word;
}

.table-informe-empleados > thead {
    text-align: center;
    background-color: var(--main-color-menuEmergente-color);
    color: white;

}

.table-informe-empleados > thead > tr > th {
    /*width: 3em;*/

}

.table-informe-empleados > tbody {
    background-color: white;
}

.container-table-movil {
    /*overflow-x: auto;*/
    width: auto;

}

.head-informe {
    color: black;
}

.title-header {
    color: black;

}

.optionMax > a {
    text-decoration: underline;
    color: blue;
}

.ui-alert-rut-valido {
    color: var(--main-color-azulClaro) !important;
}

.ui-alert-no-valido {
    color: #b21113 !important;
}

.margin-table-top {
    margin-top: var(--padding-body);
    overflow: auto;

}

.modal {
    -webkit-transform: translateY(var(--ui-transition-modal));
    -ms-transform: translateY(var(--ui-transition-modal));
    transform: translateY(var(--ui-transition-modal));
    overflow-y: auto;
}

.ui-modal-mobile {
    max-width: var(--ui-modal-mobile);

}

.modal-body {
    max-height: 100%;
}

.img-add {
    width: 2em;
    margin-bottom: var(--font-size);
    -webkit-transition: width 0.2s;
    -o-transition: width 0.2s;
    transition: width 0.2s;
}

.img-add:hover {
    width: 1.9em;
    cursor: pointer;

}

.ui-form-grid {
    display: -ms-grid;
    display: grid;

    grid-template-columns: repeat(var(--num-columns), var(--tamano-column));
    grid-column-gap: 2%;
    justify-content: center;

}

.ui-form-grid > div > label {
    color: black;
}

.btn-circle {
    width: 2em;
    height: 2em;
    padding: .5em 0;
    border-radius: 50%;
    text-align: center;
    font-size: 1em;
    line-height: 1.42857;
}

.btn-circle.exportar {
    position: relative;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
}

/*.btn-circle.exportar.exel {*/
    /*background-image: url("/src/imagenes/btn/exels.svg");*/
/*}*/

/*.btn-circle.exportar.pdf {*/
    /*background-size: 65%;*/
    /*background-image: url("/src/imagenes/btn/pdf.svg");*/
/*}*/

/*.btn-circle.exportar.csv {*/
    /*background-image: url("/src/imagenes/btn/csv.ico");*/
/*}*/

/*.btn-circle.exportar.xml {*/
    /*background-image: url("/src/imagenes/btn/xml.svg");*/
/*}*/

/*.btn-circle.exportar.doc {*/
    /*background-image: url("/src/imagenes/btn/iconWord.png");*/
/*}*/

.btn-circle.exportar:hover {
    background-color: rgba(0, 0, 0, .3);
    border-color: black;
}

.botoneraDecargas {
    padding-left: 1em;
    padding-bottom: .7em;
    padding-top: 1em;
}

.ui-ajustar-btn {
    position: var(--position-div-reporte);
}

.ui-ajustar-btn > div {
    position: var(--position-button-reporte);
    bottom: 0;

}

.ui-modal-completo {
    background-color: rgba(0, 0, 0, .9);
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    position: fixed;
    background-image: url("/page/recursos/imagenes/load.gif");
    background-repeat: no-repeat;
    background-size: 3em;
    background-position: center;
    background-position-y: 40%;
}

.ui-modal-completo:before {
    content: "";
    position: absolute;
    width: 2em;
    height: 2em;
    top: var(--top-modal);
    left: var(--left-modal);
    text-shadow: 0.008em 0 #ffffff;
    display: var(--display-cargando);

}

/*revisar clases*/
.ui-select-informes {
    width: 17em;
    margin-bottom: 2em;
}

.table-segundaria-marcacion > thead:first-child > th {
    background-color: #FFFCF5;
    color: #9AA4B3;

}

.table-primaria-marcacion {
    table-layout: auto !important;
}


.datepicker td, .datepicker th {
    color: black !important;
}

.ui-container-alert {
    position: fixed;
    width: 100vw;
    z-index: 9999;
}

.ekoalerta {
    text-shadow: 0 .4px white;
    color: rgba(240, 250, 243, 0.12);
}

.alert-warning {
    background-color: #FFE97D !important;
    color: #80753E;
}

.alert-danger {
    background-color: #DF4C57 !important;
    border-color: rgba(180, 93, 106, .6);
}

.alert-success {
    background-color: #34B353 !important;
    border-color: rgba(52, 142, 83, .5);
}

.span-error404 {
    width: 8em;
    background-color: #DF4C57;
    text-shadow: 0 .4px white;
    margin-right: .5em;
}

.ui-bottom-error404 {
    position: fixed;
    width: 100vw;
    height: 5em;
    bottom: 0;
    background-color: var(--main-color-menu-negro);

}

.ui-texto-error404 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    line-height: 3em;
    color: var(--color-texto-celeste);
    font-family: HelveticaNeueW01-45Ligh;
    text-align: center;

}

.ui-texto-error404 .container_text {
    margin: auto;
    -webkit-transform: translateY(-6em);
    -ms-transform: translateY(-6em);
    transform: translateY(-6em);
}

.ui-icono-error404 {
    width: var(--icono-ampolleta);
    margin-bottom: 1em;
}

.form-control:disabled, .form-control[readonly] {
    background-color: transparent;
    opacity: 1;
}

.form-text.text-muted.add {
    color: #34B353 !important;
    display: inline-block;
    margin-top: 0;
    margin-left: 1px;
}


.img-add.representante {
    width: 1.6em;
}

.img-add.representante:hover {
    width: 1.9em;
    cursor: pointer;

}

input.visited:valid {
    border-bottom: 1px solid #4CAF50;
    -webkit-box-shadow: 0 1px 0 0 #4CAF50;
    box-shadow: 0 1px 0 0 #4CAF50;
}

input.visited:valid:focus {
    border: inherit;
}

input.visited:invalid {
    border-bottom: 1px solid  #b21113;
    -webkit-box-shadow: 0 1px 0 0  #b21113;
    box-shadow: 0 1px 0 0  #b21113;
}

input.visited:invalid:focus {
    border: inherit;
}

input.visited.rut {
    border-bottom: 1px solid  #b21113;
    -webkit-box-shadow: 0 1px 0 0  #b21113;
    box-shadow: 0 1px 0 0  #b21113;
}

input.visited.rut:focus {
    border: inherit !important;
}

.icon-bien {
    font-size: 1.3em;
    color: #34B353;
    padding: 0.5em
}

.home {
    font-family: HelveticaNeueW01-45Ligh;
    /*height:var(--home);!*revisar*!*/
    color: black;
    padding: 2em;
    background-color: white;
    -webkit-box-shadow: 0px 0px 6px #9AA4B3;
    box-shadow: 0px 0px 6px #9AA4B3;
    border-radius: .8em;
}

.home > h3 {
    color: grey;
}

.btnEmpresasIndex {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: var(--gridIndex);
    grid-template-columns: var(--gridIndex);
    grid-row-gap: .5em;
    grid-column-gap: 1em;
    margin-bottom: 7em;

}

.margin-user {
    margin-top: var(--marginUser);
    /*margin-top: 3em;*/
}

.datalist {

    background-color: white !important;
    z-index: 10;
    position: absolute;
    list-style: none;
    color: black !important;
    padding: 0;
    width: 92%;
    max-height: 16em;
    overflow: auto;

}

.datalist li:last-child {
    border-bottom: 1px solid grey !important;

}

.datalist li {
    padding: 0.5em;
    border-bottom: rgba(172, 172, 172, 0.5);
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
    /*border-bottom: 1px solid grey!important;*/

}

.datalist li:hover {
    background: var(--main-color-azulClaro);
    color: white !important;
    text-shadow: 0 .4px white;
}

#ingresar {
    background-image: url("/page/recursos/imagenes/iconoflecha.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 97%;
    background-size: .9em;

}


.modal-editar {

    display: flex;
    width: 100%;
    justify-content: space-around;

}

.titulo-hover:hover {
    text-decoration: underline;
    color: blue !important;
    cursor: pointer;
}


.modalhide {
    display: none;
}

.contentForm {
    /*background: rgba(255, 255, 255, .4);*/
    /*border: 1px solid rgba(0, 0, 0, .1);*/
    /*border-radius: 8px;*/
    padding: 1em
}
.makeStyles-drawerHeader-9 {
    min-height: 45px!important;
}
.MuiToolbar-regular {
     min-height: 40px!important;
}
/*button, input, optgroup, select, textarea {*/
    /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;*/
/*}*/







