
.icon-arrow-angle {
    background-image: url("/page/recursos/imagenes/iarrowangle.svg");
    /*background-repeat: no-repeat;*/
    /*width: 15px;*/
    /*height: 15px;*/
    /*min-width: 15px;*/
    /*min-height: 15px;*/
    background-repeat: no-repeat;
    background-size: 10px;
    display: block;
    height: 10px;
    width: 10px;
}

.icon-plus {
    background-image: url("/page/recursos/imagenes/iplus.png");
    /*background-repeat: no-repeat;*/
    /*width: 15px;*/
    /*height: 15px;*/
    /*min-width: 15px;*/
    /*min-height: 15px;*/
    background-repeat: no-repeat;
    background-size: 10px;
    display: block;
    height: 10px;
    width: 10px;
}

.icon-less {
    background-image: url("/page/recursos/imagenes/iless.png");
    /*background-repeat: no-repeat;*/
    /*width: 15px;*/
    /*height: 15px;*/
    /*min-width: 15px;*/
    /*min-height: 15px;*/
    background-repeat: no-repeat;
    background-size: 10px;
    display: block;
    height: 10px;
    width: 10px;
}

.icon-refrescar-mapa {
    background-image: url("/page/recursos/imagenes/irefrescarmapa.png");
    /*background-image: url("/page/recursos/imagenes/iplus.png");*/
    background-repeat: no-repeat;
    background-size: 10px;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.icon-refrescar-lista {
    background-image: url("/page/recursos/imagenes/irefrescarlista.png");
    /*background: url(/page/recursos/imagenes/iplus.png);*/
    background-repeat: no-repeat;
    background-size: 10px;
    display: inline-block;
    height: 10px;
    width: 10px;
}

.borde {
    border: 1px solid #b7b7b7;
    border-radius: 5px;
}


.column {
    width: 50px;
    /*font-size: 1em;*/
    float: left;
}

/****************************************/
.filtro-principal {
    /*width:300px;*/
    z-index: 1 !important;
    display: block;
    top: initial !important;
    position: fixed !important;


}

.filtro-principal .content-btn button {
    /*//Se da este estilo para que el texto no se salga del boton, pero este texto se debe cambiar por un icono de lupa*/
    overflow: hidden;
}

.filtro-principal .content-cmb button.multiselect {
    background-color: white;
}

.filtro-principal .content-cmb {
    display: inline-block;
    width: 75%;
}

.filtro-principal .content-btn {
    display: inline-block;
    width: 25%;
}

.multiselect-container > li > a > label {
    padding: 0px 0 0px 10px;
}


.filtro-principal .multiselect {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #b7b7b7;
    border-right: 0;

}

.filtro-principal .btn-primary {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #b7b7b7;
    border-left: 0;
}


.filtro-principal label.checkbox {
    white-space: nowrap;
}

.filtro-lateral {
    /*display: none;*/
    background-color: white;
    padding: 10px;
    border-bottom-left-radius: 10px !important;
    border-top-left-radius: 10px !important;
    max-width: 40%;
    max-height: 80%;
    display: var(--hiddenItemsMenu);
}

.filtro-lateral.title {
    color: black;
    /*font-size: 16px;*/
}


.filtro-lateral.icon-menu {
    float: right;
}


.filtro-lateral .content-table {
    max-height: 600px;
    overflow: scroll;
}

.filtro-lateral table {
    margin-bottom: 0;
}

.filtro-lateral table .collapse.in {
    display: block;
}

.filtro-lateral table .th-show-hide {
    width: 22px;
}

.filtro-lateral table .collapsed i {
    /*background-image: url("/page/recursos/imagenes/iplus.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: 10px;*/
    display: block;
    height: 10px;
    width: 10px;
}

.filtro-lateral table i {
    cursor: pointer;
    /*background-image: url("/page/recursos/imagenes/iless.png");*/
    /*background-repeat: no-repeat;*/
    /*background-size: 10px;*/
    display: block;
    height: 10px;
    width: 10px;
}

.filtro-lateral.actualizacion-lista {
    font-size: small;
    color: #000;
}

/*******************************/

.content-actualizacion {
    visibility: hidden;
    background: transparent;
    padding: 10px;
    margin-top: 1em;

    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-size: medium;
    color: black;
    text-shadow: 1px 0px white;
    width: 120px;
}


.content-actualizacion label {
    margin: 0;
}

.content-actualizacion div {
    display: inline-block;
    width: 41%;
}

/******************************/
.content-trafico {
    background: rgba(255, 255, 255, 0);
    padding: 10px;
    color: black;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.content-trafico input[type="checkbox"] {
    position: relative;
    vertical-align: middle;
    bottom: 1px;
}

.content-trafico label {
    font-size: small;
    color: black;
    margin: 0;
}

/****************************/

.content-simbologia {
    background-color: white;
    padding: 10px;
    border-bottom-right-radius: 10px !important;
    border-top-right-radius: 10px !important;
    color: black !important;
    font-size: small;
}

.icon-menu {
    /*Estilo que asigna icono debe ser eliminado y al alemento agregarle la clase de icono*/
    /*background: url(/page/recursos/imagenes/menu.svg);*/
    /*background-repeat: no-repeat;*/
    /*background-size: 10px;*/
    /*display: block;*/
    /*height: 10px;*/
    /*width: 10px;*/

    background-image: url("/page/recursos/imagenes/iplus.png");
    background-repeat: no-repeat;
    background-size: 10px;
    display: block;
    height: 10px;
    width: 10px;
}

.icon-menu.cierreDos {

    background-image: url("/page/recursos/imagenes/iless.png");
}

.content-simbologia i {
    display: inline-block;
    margin-right: 5px;
}

.content-simbologia svg {
    width: 20px;
    height: 20px;
}

.content-simbologia .simbologia-seccion {
    padding: 5px;
}

.simbologia-seccion:not(:last-child) {
    margin-bottom: 5px;
}

.content-simbologia .vehiculos table {
    color: black !important;
}

.content-simbologia .vehiculos .item {
    font-weight: bold;
    /*font-size: small;*/
}

.content-simbologia .vehiculos .descripcion {
    font-size: small;
    /*color: black;*/
}

.img-trafico {
    height: 5px;
    width: 40px;
    display: inline-block;
    position: relative;
    top: -3px;
}

.img-trafico.baja {
    background-color: #84ca50;
}

.img-trafico.media {
    background-color: #f07d02;
}

.img-trafico.alta {
    background-color: #e60000;
}

.img-estado-conduccion {
    height: 14px;
    width: 14px;
    display: inline-block;
    position: relative;
    top: 1px;
}

.img-estado-conduccion.baja {
    background-color: yellow;
}

.img-estado-conduccion.precaucion {
    background-color: #f07d02;
}

.img-estado-conduccion.alerta {
    background-color: #e60000;
}

.img-estado-conduccion.error {
    width: 100%;
}

.img-estado-conduccion.error:before {
    content: 'No definido';

}

.content-icon {
    text-align: center;
}


.info-window {
    color: black;
}

.info-window .item {
    font-weight: bold;
    font-size: small;
}

.info-window .descripcion {
    font-size: small;
    min-width: 50px;
}

label.checkbox {
    color: #495057;
}

.labelCupoMarker {

    color: white;
    height: 20px;
    text-align: center;
    white-space: nowrap;
    top: 0px;
    font-weight: bold;
    font-size: 1em;
    z-index: 10;
}

.labelVelocidadMarker {
    /*color: white;*/
    /*text-shadow: -0.5px 0 black, 0 0.5px black, 0.5px 0 black, 0 -0.5px black;*/

    fontSize: "1.4em";
    text-align: center;
    white-space: nowrap;
    top: 0px;
    font-weight: 100;
    font-size: 1em;
    z-index: 10;
    border: 1px solid black;
    border-radius: 1px 1px 1px;
    padding: 1em;
}


.btnUnido {
    border: 1px solid #ced4da;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}

.inputUnido {
    border-bottom-left-radius: 0;
}

.iconCierre {
    position: absolute;
    top: 0;
}

.mapa {
    height: var(--heightMapa) !important;
}

.subMenuMapa {
    position: fixed;
    bottom: 0;
    /*height: var(--heightSubMenu);*/
    height: auto;
    display: var(--displaySubMenu);
    min-width: 100%;
    background: #fdfdfa;
    color: #0c0c0c;
    z-index: 1000000;


}

.subMenuItemsDos {
    padding: .5em;
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.subMenuItemsDos div {
    text-align: center;
    position: relative;

}

.subMenuItemsDos div:after {
    content: "";
    position: absolute;
    bottom: 5px;
    left: 35%;
    width: 0%;
    height: 4px;
    background-color: #1c7430;
    transition: all 1s;

}

.subMenuItemsDos div:hover:after {
    width: 30%;
}


.mapaOffset {
    position: fixed;
    margin-top: var(--body-margin-top);
    top: 53%;
    height: var(--heightMapa);
    width: 100%;
    background-color: snow;
    display: var(--displaySubMenu);
    right: -100%;
    max-width: 100%;
    overflow: hidden;
    transition: all 2s;

}

.mapaOffset.drag {
    top: 0;
    height: 100%;

}


.mapaOffset.show {
    right: inherit;
}

.divContenedor {
    padding: 1em;
}

.contain-check {
    width: 100%;
    border-radius: 5px;
    /*border-right: 0;*/
    /*border-left: 0;*/
    margin-bottom: .6em;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.contain-check label {
    width: 100%;

}

.btn-circle-extend {
    height: 3.5em;
    width: 3.5em;
}

.form-check input[type="checkbox"] {
    display: none;
}

.form-check input[type="checkbox"]:checked + label {

    text-shadow: 0 .4px white;
    background-color: #c9cccf;
    border-color: #dee2e6;
}

label.form-check-label {
    position: relative;
    color: white !important;
}

label.form-check-label span {
    position: absolute;
    left: 2%;
    top: 30%;

}


.form-check input[type="checkbox"]:checked + label span {
    color: var(--success);

}

.searchDiv {
    overflow: auto;
    max-height: var(--heightMapa);

}

.subMenuMap {
    background-color: var(--main-color-menu-negro);
    color: white;
}

.gm-style-mtc {
    visibility: var(--hidenMaps) !important;
}

.gm-control-active.gm-fullscreen-control {
    visibility: var(--hidenMaps) !important;

}

/*.input-group-addon,*/
/*.input-group-btn,*/
/*.input-group .form-control {*/
/*display: table-cell;*/
/*}*/
/*.input-group-addon:not(:first-child):not(:last-child),*/
/*.input-group-btn:not(:first-child):not(:last-child),*/
/*.input-group .form-control:not(:first-child):not(:last-child) {*/
/*border-radius: 0;*/
/*}*/
/*.input-group-addon,*/
/*.input-group-btn {*/
/*width: 1%;*/
/*white-space: nowrap;*/
/*vertical-align: middle;*/
/*}*/
.input-group-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: 0
}

/*.input-group-addon.input-sm {*/
/*padding: 5px 10px;*/
/*font-size: 12px;*/
/*border-radius: 3px;*/
/*}*/
/*.input-group-addon.input-lg {*/
/*padding: 10px 16px;*/
/*font-size: 18px;*/
/*border-radius: 6px;*/
/*}*/
/*.input-group-addon input[type="radio"],*/
/*.input-group-addon input[type="checkbox"] {*/
/*margin-top: 0;*/
/*}*/
/*.input-group .form-control:first-child,*/
/*.input-group-addon:first-child,*/
/*.input-group-btn:first-child > .btn,*/
/*.input-group-btn:first-child > .btn-group > .btn,*/
/*.input-group-btn:first-child > .dropdown-toggle,*/
/*.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),*/
/*.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {*/
/*border-top-right-radius: 0;*/
/*border-bottom-right-radius: 0;*/
/*}*/
/*.input-group-addon:first-child {*/
/*border-right: 0;*/
/*}*/
/*.input-group .form-control:last-child,*/
/*.input-group-addon:last-child,*/
/*.input-group-btn:last-child > .btn,*/
/*.input-group-btn:last-child > .btn-group > .btn,*/
/*.input-group-btn:last-child > .dropdown-toggle,*/
/*.input-group-btn:first-child > .btn:not(:first-child),*/
/*.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {*/
/*border-top-left-radius: 0;*/
/*border-bottom-left-radius: 0;*/
/*}*/
/*.input-group-addon:last-child {*/
/*border-left: 0;*/
/*}*/

span.multiselect-native-select {
    position: relative
}

span.multiselect-native-select select {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px -1px -1px -3px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    left: 50%;
    top: 30px
}

.multiselect-container {
    position: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0
}

.multiselect-container .input-group {
    margin: 5px
}

.multiselect-container > li {
    padding: 0
}

.multiselect-container > li > a.multiselect-all label {
    font-weight: 700
}

.multiselect-container > li.multiselect-group label {
    margin: 0;
    padding: 3px 20px 3px 20px;
    height: 100%;
    font-weight: 700
}

.multiselect-container > li.multiselect-group-clickable label {
    cursor: pointer
}

.multiselect-container > li > a {
    padding: 0
}

.multiselect-container > li > a > label {
    margin: 0;
    height: 100%;
    cursor: pointer;
    font-weight: 400;
    padding: 3px 20px 3px 40px
}

.multiselect-container > li > a > label.radio, .multiselect-container > li > a > label.checkbox {
    margin: 0
}

.multiselect-container > li > a > label > input[type=checkbox] {
    margin-bottom: 5px
}

.btn-group > .btn-group:nth-child(2) > .multiselect.btn {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
}

.form-inline .multiselect-container label.checkbox, .form-inline .multiselect-container label.radio {
    padding: 3px 20px 3px 40px
}

.form-inline .multiselect-container li a label.checkbox input[type=checkbox], .form-inline .multiselect-container li a label.radio input[type=radio] {
    margin-left: -20px;
    margin-right: 0
}

.multiselect-clear-filter {
    padding: 9px 12px;
}

/*.multiselect-item.filter{*/
/*margin-right: 3em;*/
/*}*/
/*.input-group-addon i{*/
/*right: 5px;*/
/*}*/

.openMax {
    background-image: url("/page/recursos/imagenes/minuss.svg");
    background-size: 2em;
    background-position: center;
    background-repeat: no-repeat;
    height: 1.5em;
    box-shadow: 0px 2px 2px #80808040;
}

.maxWindow {
    overflow: auto;
    max-height: 50%;
}

.maxWindow.drag {

    max-height: 100%;
}

span.input-group-addon > i {
    top: 5px;
}

.iconoActivo {
    color: red;

}

@media (min-width: 600px) {
    .makeStyles-drawerHeader-10 {
        min-height: 48px !important;
    }
}