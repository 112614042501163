:root{
    /*//elementos hover elementos y botones*/
    --main-color-blue:#81B71A;
    --main-color-blue-border: #00650E;
    --main-color-button-hover-background:#A0E320;
    --main-color-button-hover-border:#2057CC;

    /*paleta de colores*/
    --main-color-background-login:#A2E521;

    --main-color-yellow:rgb(255,236,65);
    --main-color-yellowOpaco:rgba(255,236,65,.3);
    --main-color-red:rgb(255,84,21);

    /*--main-icon-color:#1d2329;*/
    --main-color-login-icon:#005073;
    --main-color-login-icon:#DDE2FF;
    --main-color-menuEmergente-color:rgba(52,58,64,.6);
    --main-color-redS:red;
    --probar-color-borde-tablas:#2ad055;
    --main-font-color: #ffff;

    /*tema color oscuro*/
    --main-color-menu-negro:#262626;
    --main-color-body-background:#eee;
    /*--main-color-azulClaro: #2970aa;*/
    --main-color-azulClaro: #4f5b62;
    --color-fondo-label:#00bde8;/*probar*/
    --color-texto-celeste:#3eb8ea;

    /*fuentes*/
    --font-size:0.875rem;
    --font-size-table:0.875rem;
    --h1-font-size:2rem;
    --h2-font-size:1.5rem;
    --h3-font-size:1rem;
    --h4-font-size:0.875rem;
    /*tamaño elementos*/
    --menu-hidden-width:100%;
     --padding-body:1.5em 0 0;
    --main-width-icono:1.3em;
    --body-margin-top:3em;
    --margin-top-login:1em;
    --login-font-size-icono:3.5em;
    --ui-modal-mobile:98%;
    --ui-transition-modal:2%;
    --num-columns:1;
    --tamano-column:100%;
    --fixed-column:none;
    --position-div-reporte:static;
    --position-button-reporte:static;
    --left-modal:42%;
    --top-modal:45%;
    --icono-ampolleta:28%;
    --display-cargando:none;
    --home:38vh;
    --gridIndex:1fr;
    --marginUser:10em;

    --displaySubMenu:block;
    --heightSubMenu:8%;
    --heightMapa:92%;
    --hiddenItemsMenu:none;
    --hidenMaps:hidden;
}
/*  cambio para pantallas mayores a 700px */
@media (min-width: 1701px) {
    :root{
        /*fuentes*/
        --font-size:1rem;
        --h1-font-size: 2.5rem;
        --h2-font-size: 1.75rem;
        --h3-font-size: 1.2rem;
        --h4-font-size: 1rem;
        /*tamaño elementos*/
        --menu-hidden-width:22%;
        --margin-body-menu-hidden:22%;
        --padding-body-menu-hidden:1em;
        --padding-body:2em;
        --main-width-icono:1.8em;
        --body-margin-top:3.1em;
        --margin-top-login:4em;
        --login-font-size-icono:4em;
        --ui-modal-mobile:80%;
        --ui-transition-modal:10%;
        --num-columns:2;
        --tamano-column:40%;
        --fixed-column:fixed;
        --position-div-reporte:relative;
        --position-button-reporte:absolute;
        --left-modal:48%;
        --top-modal:45%;
        --icono-ampolleta:28%;
        --display-cargando:block;
        --home:23vh;
        --gridIndex:1fr;
        --marginUser:22em;

        --displaySubMenu:none;
        --heightSubMenu:0%;
        --heightMapa:100%;
        --hiddenItemsMenu:inherit;
        --hidenMaps:visible;
    }
}

@media (max-width: 850px) and (orientation: landscape) {


    :root{

        /*fuentes*/
        --font-size:1rem;
        --h1-font-size: 2.5rem;
        --h2-font-size: 1.75rem;
        --h3-font-size: 1.2rem;
        --h4-font-size: 1rem;
        /*tamaño elementos*/
        --menu-hidden-width:100%;
        --margin-body-menu-hidden:36%;
        --padding-body-menu-hidden:1em;
        --padding-body:1em;
        --main-width-icono:1.8em;
        --body-margin-top:3.1em;
        --margin-top-login:0.1em;
        --login-font-size-icono:0;
        --ui-modal-mobile:98%;
        --ui-transition-modal:0%;
        --num-columns:1;
        --tamano-column:100%;
        --fixed-column:none;
        --left-modal:46%;
        --top-modal:50%;
        --icono-ampolleta:10%;
        --display-cargando:none;
        --home:70vh;
        --gridIndex:1fr;
        --displaySubMenu:block;
        --heightSubMenu:8%;
        --heightMapa:90%;
        --hiddenItemsMenu:none;
    }
}

@media (min-width: 768px) and (max-width: 999px)  {
    :root{
        --font-size:1rem;
        --h1-font-size: 2.5rem;
        --h2-font-size: 1.75rem;
        --h3-font-size: 1.2rem;
        --h4-font-size: 1rem;
        --position-div-reporte:relative;
        --position-button-reporte:absolute;
        --display-cargando:none;
        --displaySubMenu:none;
        --displaySubMenu:block;
        --heightSubMenu:7.3%;
        --heightMapa:92%;
        --hiddenItemsMenu:none;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1700px)  {
    :root{
        /*fuentes*/
        --font-size:1rem;
        --h1-font-size: 2.5rem;
        --h2-font-size: 1.75rem;
        --h3-font-size: 1.2rem;
        --h4-font-size: 1rem;
        /*tamaño elementos*/
        --menu-hidden-width:28%;
        --margin-body-menu-hidden:28%;
        --padding-body-menu-hidden:1em;
        --padding-body:2em;
        --main-width-icono:1.8em;
        --body-margin-top:3.1em;
        --margin-top-login:4em;
        --login-font-size-icono:4em;
        --ui-modal-mobile:80%;
        --ui-transition-modal:10%;
        --num-columns:2;
        --tamano-column:40%;
        --fixed-column:fixed;
        --position-div-reporte:relative;
        --position-button-reporte:absolute;
        --left-modal:47%;
        --top-modal:45%;
        --icono-ampolleta:28%;
        --display-cargando:block;
        --home:23vh;
        --gridIndex:1fr;
        --marginUser:18em;
        --displaySubMenu:none;
        --hiddenItemsMenu:inherit;
        --heightSubMenu:0%;
        --heightMapa:100%;
        --hidenMaps:visible;
    }
}