
.ui-login{

    /*padding-top: var(--margin-top-login);*/


}
.ui-login-fixed{
    height: var(--body-margin-top);

}

.ui-body-background{

    /*background-image: linear-gradient(201deg, #383d41 79%,transparent 78%);*/
    background-color:  #383d41;
}
.ui-form-login{
    padding: 1em;

}
.ui-login-icono{
    font-size:var(--login-font-size-icono);
    text-align: center;
}

