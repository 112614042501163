.calendar {
    background-color: transparent;
}

.form-control {
    background-color: transparent !important;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 16px;
    margin: 0 0 8px 0;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-transition: border .3s, -webkit-box-shadow .3s;
    transition: border .3s, -webkit-box-shadow .3s;
    transition: box-shadow .3s, border .3s;
    transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
}


.form-control:focus {
    /*border-bottom: 1px solid #6200ee;*/
    /*-webkit-box-shadow: 0 1px 0 0 #6200ee;*/
    /*box-shadow: 0 1px 0 0 #6200ee;*/
    /*background-color: transparent!important;*/
    border-bottom: 1px solid #4f5b62;
    -webkit-box-shadow: 0 1px 0 0 #4f5b62;
    box-shadow: 0 1px 0 0 #4f5b62;
    background-color: transparent !important;

}

.btn-primary {
    color: #fff;
    background-color: #26a69a;
    border-color: #26a69a;


}

.btn-primary:hover {
    opacity: .9;
    color: #fff;
    background-color: #2dbda5;
    border-color: #26b1a5;
}

.month.focused.active {
    background-color: #817b87 !important;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
    /*background-color: #039\9;*/
    background: #817b87;
}

.modal {
    z-index: 5000;
    height: 99%
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25) !important;
}

/*revisar sino cambiar por input*/
.MuiTableCell-alignRight {
    text-align: left !important;
    flex-direction: initial !important;
}

div.MuiFormControl-root {
    float: none !important;
}

.MuiTab-root {
    line-height: initial !important;
}

.MuiTypography-body1 {
    font-size: 0.8rem !important;
}

.MuiTableCell-head {
    line-height: initial !important;
}